import './App.css';
import ListaZakupow from './ListaZakupow';

function App() {
  return (
    <div className="App">
      <ListaZakupow />
    </div>
  );
}

export default App;
