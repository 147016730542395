import {useState} from "react";
import { uuid } from 'uuidv4';

export default (init) => {
    const [lista,setLista] = useState(init);

    const store = () => {
        localStorage.setItem("lista",JSON.stringify(lista));
    }

    const addElement = (nazwa) => {
        setLista([...lista, {nazwa: nazwa, zrobione: false, id: uuid()}]);
        store();
    }

    const editElement = (id,nowaNazwa) => {
        setLista(lista.map(el => el.id === id ? {nazwa: nowaNazwa, zrobione: el.zrobione, id: el.id} : el));
        store();
    } 

    const deleteElement = (id) => {
        setLista(lista.filter(el => el.id != id));
        store();
    }

    const toggleZrobione = (id) => {
        setLista(lista.map(el => el.id === id ? {nazwa: el.nazwa, zrobione: !el.zrobione, id: el.id} : el));
        store();
    }

    return [lista,addElement,editElement,deleteElement,toggleZrobione];
}