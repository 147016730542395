import React from "react"
import Element from "./Element";
import { stringSimilarity } from "string-similarity-js";

import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
      },
  }));  

function Lista(props) {

    const classes = useStyles();

    const compare = (el1,el2) => {
        let s1 = stringSimilarity(el1.nazwa,props.filter);
        let s2 = stringSimilarity(el2.nazwa,props.filter);
        if (s1<s2) return 1;
        else if (s1>s2) return -1;
        else return 0;
    }
    return <div>
        
        <h2>NIEGOTOWE</h2>
        <List className={classes.root}>
        {props.elementy.map(e => e).sort(compare).map(el =>
            el.zrobione ? <div></div> : <Element filter={props.filter} delete={props.delete} edit={props.edit} key={el.id} element={el} zrobione={props.zrobione}/>)}
        </List>
        
        <h2>GOTOWE</h2>
        {props.elementy.map(e => e).sort(compare).map(el =>
            el.zrobione ? <Element filter={props.filter} delete={props.delete} edit={props.edit} key={el.id} element={el} zrobione={props.zrobione}/> : <div></div>)}
    </div>
}

export default Lista;