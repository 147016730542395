import {useState} from "react"

export default init => {
    const[val,setVal] = useState(init);
    const setText = (evt) => {
        setVal(evt.target.value);
        
    }

    return [val,setText];
}