import React, { useState } from "react"
import useInput from "./hooks/useInput";


import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    button: {
        margin: theme.spacing(1),
      },
  }));  

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });  

export default function Element(props) {

    const classes = useStyles();
    

    const [editMode,setEditMode] = useState(false);
    const [value,setValue] = useInput(props.element.nazwa);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };    

    const finishEdit = (id,newName) => {
        props.edit(props.element.id,newName);
        setEditMode(false);
    }

    const styleCommon = (string, search) => {
        if (!string.includes(search)) return <span>{string}</span>;
        else return string.split(search).map(el=> <span>{el}</span>).flatMap((value,index,array) => array.length - 1 !== index ? [value,<span style={{fontWeight:"bold", backgroundColor:"#e5e5e5"}}>{search}</span>] : value);
    }
    return <div>
        {editMode 
            ?
            <ListItem key={props.element.id} role={undefined} dense button>

                <TextField id="standard-basic" label="Nowa nazwa" value={value} onChange={setValue}/>
                <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<CloudUploadIcon />}
                        onClick={() => finishEdit(props.element.id,value)}
                    >
                        Zapisz
                    </Button>
                </ListItem>
            
            :
       
              
                <ListItem key={props.element.id} role={undefined} dense button>
                    <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={props.element.zrobione}
                        tabIndex={-1}
                        disableRipple
                        onChange={() => props.zrobione(props.element.id)}
                    />
                    </ListItemIcon>
                    <ListItemText primary={styleCommon(props.element.nazwa,props.filter)} />
                    <ListItemSecondaryAction>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<CloudUploadIcon />}
                        onClick={() => setEditMode(true)}
                    >
                        Edytuj
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        //onClick={() => props.delete(props.element.id)}
                        onClick={handleClickOpen}
                        
                    >
                        Usuń
                    </Button>
                    </ListItemSecondaryAction>
                </ListItem>
        }
        {
            2==2 ?
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Potwierdź usunięcie"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Czy chcesz usunąć {props.element.nazwa} ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Anuluj
                </Button>
                <Button onClick={() => props.delete(props.element.id)} color="primary">
                    Usuń
                </Button>
                </DialogActions>
            </Dialog>
      :
      <div></div>
        }
        
    </div>
}