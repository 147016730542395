import React from "react"
import useInput from "./hooks/useInput"

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
      },
  }));  

export default function Dodaj(props) {
    const classes = useStyles();
    const [name,setName] = useInput("");
    return <div>
        <TextField id="standard-basic" label="Nazwa" value={name} onChange={setName} />
                <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<CloudUploadIcon />}
                        onClick={() => {
                            props.addElement(name);
                        }}
                    >
                        Dodaj
                    </Button>
    </div>
}